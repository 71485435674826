import styles from "../../css/tallas-component.module.css"
import React from "react"
import Title from "../title-component"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"
const getTalla = graphql`
  query {
    talla: file(relativePath: { eq: "guiaSize.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Tallas = () => {
  const data = useStaticQuery(getTalla)
  const tallaImg = data.talla.childImageSharp.fluid
  return (
    <section className={styles.tallas}>
      <Title
        title={<FormattedMessage id="tallasTitle1" />}
        subtitle={<FormattedMessage id="tallasTitle2" />}
      />
      <p>
        <FormattedMessage id="textoTallas" />
      </p>
      <Img fluid={tallaImg} />
    </section>
  )
}

export default Tallas
