import React from "react"
import Layout from "../components/layout-component"
import TallasComponent from "../components/Tallas/tallas-component"
import { graphql } from "gatsby"
import StyledHero from "../components/StyledHero"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"

const Tallas = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={`${intl.formatMessage({ id: "titleTallas" })}`}
        description={`${intl.formatMessage({ id: "descTallas" })}`}
        keywords={[
          `${intl.formatMessage({ id: "TallasKeyword1" })}`,
          `${intl.formatMessage({ id: "TallasKeyword2" })}`,
        ]}
        lang={intl.locale}
      />
      <StyledHero img={data.tallaHero.childImageSharp.fluid} />
      <TallasComponent />
    </Layout>
  )
}
export const query = graphql`
  query {
    tallaHero: file(relativePath: { eq: "midiendoSombrero.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Tallas
